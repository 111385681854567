import React, { Component } from "react";
import styled from "styled-components";
import { Input, Form, Select, Spin, notification } from "antd";
import axios from "axios";
import { connect } from "react-redux";
//Styles
import {
  FlexibleRowContainer,
  VerticalContainer,
  minTabletBreakpoints,
  minMobileBreakpoints,
} from "../components/Layout";
import { BlueColor, DarkGreyColor, GreyColor } from "../utils/Color";
import {
  Title1,
  Title3,
  Title5,
  Title6,
  Headline,
} from "../components/Typography";
//Components
import Header from "../components/Header";
import Footer from "../components/Footer";
import WindowResizer from "../components/WindowResizer";
//Assets
import doneIcon from "../assets/done-icon.png";
import download from "../assets/download-here.png";
import apple from "../assets/app-store.png";
import playStore from "../assets/play-store.png";
import applogo from "../assets/app-theatre-logo-coloured-new.png";
//Utils
import StateList from "../utils/stateList";
import registerList from "../utils/RegisterTranslation";
import errorList from "../utils/ErrorTranslation";
import notificationErrorList from "../utils/NotificationErrorTranslate";
import existingRegisterList from "../utils/RegisterExistingTranslation";
//Utils
import ReactGA from "react-ga";

const { Option } = Select;

class Register extends Component {
  state = {
    name: "",
    phone: "",
    state: "",
    email: "",
    device: "",
    code: "",
    nameError: "",
    phoneError: "",
    stateError: "",
    emailError: "",
    deviceError: "",
    codeError: "",
    newName: "",
    newPhone: "",
    newState: "",
    newEmail: "",
    newPassword: "",
    newPasswordConfirm: "",
    newDevice: "",
    newCode: "",
    newNameError: "",
    newPhoneError: "",
    newStateError: "",
    newEmailError: "",
    newPasswordError: "",
    newPasswordConfirmError: "",
    newDeviceError: "",
    newCodeError: "",
    registered: false,
    loading: false,
    user: null,
  };

  handleLoadingStop = () => {
    this.setState({
      loading: false,
    });
  };

  handleSelectNewState = (value) => {
    this.setState({
      newState: value,
    });
  };

  handleSelectState = (value) => {
    this.setState({
      state: value,
    });
  };

  handleSelectDevice = (value) => {
    this.setState({
      device: value,
    });
  };

  handleSelectNewDevice = (value) => {
    this.setState({
      newDevice: value,
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  //==========================================================================================
  //INFO : Handle Actions

  handleNotificationError = (key) => {
    notification.error({
      message: this.renderErrorMessage(key),
      description: this.renderErrorDescription(key),
      placement: "bottomRight",
    });
  };

  handleNextPage = () => {
    this.props.history.push("/notice");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  handleRedeem = () => {
    this.setState(
      {
        codeError: "",
        emailError: "",
        nameError: "",
        stateError: "",
        phoneError: "",
        deviceError: "",
        passwordError: "",
        loading: true,
      },
      () => {
        this.handleCheckCondition("email");
        this.handleCheckCondition("code");
        this.handleCheckCondition("name");
        this.handleCheckCondition("state");
        this.handleCheckCondition("phone");
        this.handleCheckCondition("device");
        // this.handleCheckCondition("password");

        setTimeout(async () => {
          if (
            this.state.emailError === "" &&
            this.state.codeError === "" &&
            this.state.nameError === "" &&
            this.state.phoneError === "" &&
            this.state.stateError === "" &&
            this.state.deviceError === ""
            // this.state.passwordError === ""
          ) {
            try {
              let response = await axios.post(
                "https://us-central1-mebooks-plus.cloudfunctions.net/redeemCodeDLExistingUser",
                {
                  code: this.state.code,
                  userData: {
                    email: this.state.email,
                    code: this.state.code,
                    name: this.state.name,
                    phone: this.state.phone,
                    state: this.state.state,
                    device: this.state.device,
                    date: new Date(),
                  },
                }
              );

              if (response && response.data && response.data.success) {
                this.handleLoadingStop();
                ReactGA.event({
                  category:
                    process.env.NODE_ENV === "development"
                      ? "RedeemExistingDev"
                      : "RedeemExisting",
                  action: `Redeem Code`,
                  label: `${this.state.code}`,
                });
                this.setState({
                  registered: true,
                });
              }
            } catch (err) {
              if (err.response.data.error.code === "USER_NOT_FOUND")
                this.setState(
                  {
                    loading: false,
                    emailError: this.renderErrorDescription(0),
                  },
                  () => {
                    this.handleNotificationError(0);
                  }
                );
              else if (err.response.data.error.code === "CODE_NOT_FOUND")
                this.setState(
                  {
                    loading: false,
                    codeError: this.renderErrorDescription(1),
                  },
                  () => {
                    this.handleNotificationError(1);
                  }
                );
              else if (err.response.data.error.code === "CODE_USED")
                this.setState(
                  {
                    loading: false,
                    codeError: this.renderErrorDescription(2),
                  },
                  () => {
                    this.handleNotificationError(2);
                  }
                );
              else
                this.setState(
                  {
                    loading: false,
                  },
                  () => {
                    this.handleNotificationError(3);
                  }
                );
            }
          } else {
            this.handleLoadingStop();
          }
        });
      }
    );
  };

  handleRegister = () => {
    this.setState(
      {
        newCodeError: "",
        newEmailError: "",
        newNameError: "",
        newStateError: "",
        newDeviceError: "",
        newPhoneError: "",
        newPasswordError: "",
        newPasswordConfirmError: "",
        loading: true,
      },
      () => {
        this.handleCheckNewCondition("email");
        this.handleCheckNewCondition("code");
        this.handleCheckNewCondition("name");
        this.handleCheckNewCondition("state");
        this.handleCheckNewCondition("device");
        this.handleCheckNewCondition("phone");
        this.handleCheckNewCondition("password");
        this.handleCheckNewCondition("passwordConfirm");

        setTimeout(async () => {
          if (
            this.state.newEmailError === "" &&
            this.state.newCodeError === "" &&
            this.state.newNameError === "" &&
            this.state.newPhoneError === "" &&
            this.state.newStateError === "" &&
            this.state.newPasswordError === "" &&
            this.state.newPasswordConfirmError === "" &&
            this.state.newDeviceError === ""
          ) {
            try {
              let response = await axios.post(
                "https://us-central1-mebooks-plus.cloudfunctions.net/redeemCodeDLNewUser",
                {
                  code: this.state.newCode,
                  userData: {
                    email: this.state.newEmail,
                    code: this.state.newCode,
                    name: this.state.newName,
                    phone: this.state.newPhone,
                    state: this.state.newState,
                    date: new Date(),
                    device: this.state.newDevice,
                  },
                  password: this.state.newPassword,
                }
              );

              if (response && response.data && response.data.success) {
                this.handleLoadingStop();
                ReactGA.event({
                  category:
                    process.env.NODE_ENV === "development"
                      ? "RedeemNewDev"
                      : "RedeemNew",
                  action: `Redeem Code`,
                  label: `${this.state.newCode}`,
                });
                this.setState({
                  registered: true,
                });
              }
            } catch (err) {
              if (err.response.data.error.code === "USER_NOT_FOUND")
                this.setState(
                  {
                    loading: false,
                    newEmailError: this.renderErrorDescription(0),
                  },
                  () => {
                    this.handleNotificationError(0);
                  }
                );
              else if (err.response.data.error.code === "CODE_NOT_FOUND")
                this.setState(
                  {
                    loading: false,
                    newCodeError: this.renderErrorDescription(1),
                  },
                  () => {
                    this.handleNotificationError(1);
                  }
                );
              else if (err.response.data.error.code === "CODE_USED")
                this.setState(
                  {
                    loading: false,
                    newCodeError: this.renderErrorDescription(2),
                  },
                  () => {
                    this.handleNotificationError(2);
                  }
                );
              else if (err.response.data.error.code === "USER_EXISTED")
                this.setState(
                  {
                    loading: false,
                    newEmailError: this.renderErrorDescription(4),
                  },
                  () => {
                    this.handleNotificationError(4);
                  }
                );
              else
                this.setState(
                  {
                    loading: false,
                  },
                  () => {
                    this.handleNotificationError(3);
                  }
                );
            }
          } else {
            this.handleLoadingStop();
          }
        });
      }
    );
  };

  //==========================================================================================
  //INFO : Check Conditions

  handleCheckNewCondition = (type) => {
    switch (type) {
      case "email":
        const filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if (!filter.test(this.state.newEmail))
          this.setState({
            newEmailError: this.renderErrorTitle(0),
          });
        else
          this.setState({
            newEmailError: "",
          });
        break;
      case "code":
        if (this.state.newCode.length <= 0)
          this.setState({
            newCodeError: this.renderErrorTitle(1),
          });
        else if (
          !this.state.newCode.includes("DLM") &&
          !this.state.newCode.includes("DLS") &&
          !this.state.newCode.includes("DST")
        )
          this.setState({
            newCodeError: this.renderErrorTitle(8),
          });
        break;
      case "name":
        if (this.state.newName.length <= 0)
          this.setState({
            newNameError: this.renderErrorTitle(2),
          });
        break;
      case "state":
        if (this.state.newState === "")
          this.setState({
            newStateError: this.renderErrorTitle(3),
          });
        break;
      case "device":
        if (this.state.newDevice === "")
          this.setState({
            newDeviceError: this.renderErrorTitle(7),
          });
        break;
      case "phone":
        const phoneFilter = /^(\+601)[0-46-9]-*[0-9]{7,8}$/;
        if (!phoneFilter.test(this.state.newPhone))
          this.setState({
            newPhoneError: this.renderErrorTitle(4),
          });
        break;
      case "password":
        if (
          this.state.newPassword.includes(" ") ||
          this.state.newPassword.includes(",") ||
          this.state.newPassword.length < 6
        )
          this.setState({
            newPasswordError: this.renderErrorTitle(5),
          });
        break;
      case "passwordConfirm":
        if (this.state.newPasswordConfirm != this.state.newPassword)
          this.setState({
            newPasswordConfirmError: this.renderErrorTitle(6),
          });
        break;
    }
  };

  handleCheckCondition = (type) => {
    switch (type) {
      case "email":
        const filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if (!filter.test(this.state.email))
          this.setState({
            emailError: this.renderErrorTitle(0),
          });
        else
          this.setState({
            emailError: "",
          });
        break;
      case "code":
        if (this.state.code.length <= 0)
          this.setState({
            codeError: this.renderErrorTitle(1),
          });
        else if (
          !this.state.code.includes("DLM") &&
          !this.state.code.includes("DLS") &&
          !this.state.code.includes("DST")
        )
          this.setState({
            codeError: this.renderErrorTitle(8),
          });
        break;
      case "name":
        if (this.state.name.length <= 0)
          this.setState({
            nameError: this.renderErrorTitle(2),
          });
        break;
      case "state":
        if (this.state.state === "")
          this.setState({
            stateError: this.renderErrorTitle(3),
          });
        break;
      case "device":
        if (this.state.device === "")
          this.setState({
            deviceError: this.renderErrorTitle(7),
          });
        break;
      case "phone":
        const phoneFilter = /^(\+601)[0-46-9]-*[0-9]{7,8}$/;
        if (!phoneFilter.test(this.state.phone))
          this.setState({
            phoneError: this.renderErrorTitle(4),
          });
        break;
    }
  };

  renderStateSelection = () => {
    let listView = [];
    StateList.map((eachState, index) => {
      listView.push(
        <Option key={index} value={eachState.value}>
          {eachState.label}
        </Option>
      );
    });

    return listView;
  };

  renderDeviceSelection = () => {
    let choices = ["IOS", "Android"];
    let listView = [];
    choices.map((eachDevice, index) => {
      listView.push(
        <Option key={index} value={eachDevice}>
          {eachDevice}
        </Option>
      );
    });

    return listView;
  };

  renderNewUserTitle = (key) => {
    let title = registerList[key].title;
    if (this.props.authStore && this.props.authStore.userLanguage === "BM")
      title = registerList[key].titleTranslation;
    return title;
  };

  renderExistingUserTitle = (key) => {
    let title = existingRegisterList[key].title;
    if (this.props.authStore && this.props.authStore.userLanguage === "BM")
      title = existingRegisterList[key].titleTranslation;
    return title;
  };

  renderErrorTitle = (key) => {
    let title = errorList[key].title;
    if (this.props.authStore && this.props.authStore.userLanguage === "BM")
      title = errorList[key].titleTranslation;
    return title;
  };

  renderErrorMessage = (key) => {
    let message = notificationErrorList[key].message;
    if (this.props.authStore && this.props.authStore.userLanguage === "BM")
      message = notificationErrorList[key].messageTranslation;
    return message;
  };

  renderErrorDescription = (key) => {
    let description = notificationErrorList[key].description;
    if (this.props.authStore && this.props.authStore.userLanguage === "BM")
      description = notificationErrorList[key].descriptionTranslation;
    return description;
  };

  renderExistingUserForm = () => {
    return (
      <VerticalFormContainer id="existing">
        <FormSubtitle>{this.renderExistingUserTitle(0)}</FormSubtitle>
        <FormDescription style={{ justifyContent: "center" }}>
          {this.renderExistingUserTitle(1)}
        </FormDescription>
        <CustomForm>
          <VerticalContainer
            style={{ width: "100%", alignItems: "flex-start" }}
          >
            <Label>{this.renderExistingUserTitle(2)}</Label>
            <Form.Item
              style={{ width: "100%" }}
              name="name"
              hasFeedback={this.state.nameError === "" ? false : true}
              help={this.state.nameError}
              validateStatus={this.state.nameError === "" ? null : "error"}
            >
              <Input
                id="name"
                style={{ textAlign: "left" }}
                placeholder="i.e John Doe"
                onChange={this.handleChange}
                value={this.state.name}
              />
            </Form.Item>
          </VerticalContainer>
          <VerticalContainer
            style={{ width: "100%", alignItems: "flex-start" }}
          >
            <Label>{this.renderExistingUserTitle(3)}</Label>
            <Form.Item
              style={{ width: "100%" }}
              name="phone"
              hasFeedback={this.state.phoneError === "" ? false : true}
              help={this.state.phoneError}
              validateStatus={this.state.phoneError === "" ? null : "error"}
            >
              <Input
                id="phone"
                style={{ textAlign: "left" }}
                placeholder="+60123456789"
                onChange={this.handleChange}
                value={this.state.phone}
              />
            </Form.Item>
          </VerticalContainer>
          <VerticalContainer
            style={{ width: "100%", alignItems: "flex-start" }}
          >
            <Label>{this.renderExistingUserTitle(4)}</Label>
            <Form.Item
              style={{ width: "100%" }}
              name="State"
              hasFeedback={this.state.stateError === "" ? false : true}
              help={this.state.stateError}
              validateStatus={this.state.stateError === "" ? null : "error"}
            >
              <Select
                id="state"
                placeholder={this.renderExistingUserTitle(14)}
                onChange={this.handleSelectState}
                value={this.state.state}
              >
                {this.renderStateSelection()}
              </Select>
            </Form.Item>
          </VerticalContainer>
          <VerticalContainer
            style={{ width: "100%", alignItems: "flex-start" }}
          >
            <Label>{this.renderExistingUserTitle(5)}</Label>
            <Form.Item
              style={{ width: "100%" }}
              name="email"
              hasFeedback={this.state.emailError === "" ? false : true}
              help={this.state.emailError}
              validateStatus={this.state.emailError === "" ? null : "error"}
            >
              <Input
                id="email"
                type="email"
                style={{ textAlign: "left" }}
                placeholder="i.e example@gmail.com"
                onChange={this.handleChange}
                value={this.state.email}
              />
            </Form.Item>
          </VerticalContainer>
          <VerticalContainer
            style={{ width: "100%", alignItems: "flex-start" }}
          >
            <Label>{this.renderExistingUserTitle(16)}</Label>
            <Form.Item
              style={{ width: "100%" }}
              name="Device"
              hasFeedback={this.state.deviceError === "" ? false : true}
              help={this.state.deviceError}
              validateStatus={this.state.deviceError === "" ? null : "error"}
            >
              <Select
                id="device"
                placeholder={this.renderExistingUserTitle(16)}
                onChange={this.handleSelectDevice}
                value={this.state.device}
              >
                {this.renderDeviceSelection()}
              </Select>
            </Form.Item>
          </VerticalContainer>
          <VerticalContainer
            style={{ width: "100%", alignItems: "flex-start" }}
          >
            <Label>{this.renderExistingUserTitle(6)}</Label>
            <Form.Item
              style={{ width: "100%" }}
              name="code"
              hasFeedback={this.state.codeError === "" ? false : true}
              help={this.state.codeError}
              validateStatus={this.state.codeError === "" ? null : "error"}
              required="true"
            >
              <Input
                id="code"
                type="code"
                placeholder={this.renderExistingUserTitle(13)}
                style={{ textAlign: "left" }}
                onChange={this.handleChange}
                value={this.state.code}
              />
            </Form.Item>
          </VerticalContainer>
          <FlexibleRowContainer
            style={{ justifyContent: "center", marginTop: "1S0px" }}
          >
            <button
              style={{ background: BlueColor, fontSize: "24px" }}
              className="button"
              onClick={this.handleRedeem}
            >
              {this.renderExistingUserTitle(7)}
            </button>
          </FlexibleRowContainer>
        </CustomForm>
      </VerticalFormContainer>
    );
  };

  renderNewUserForm = () => {
    return (
      <FlexibleRowContainer
        style={{ marginTop: "40px", alignItems: "flex-start", width: "100%" }}
      >
        <VerticalFormContainer id="new">
          <FormSubtitle>{this.renderNewUserTitle(0)}</FormSubtitle>
          <FormDescription style={{ color: DarkGreyColor }}>
            {this.renderNewUserTitle(1)}
          </FormDescription>
          <CustomForm>
            <VerticalContainer
              style={{ width: "100%", alignItems: "flex-start" }}
            >
              <Label> {this.renderNewUserTitle(2)}</Label>
              <Form.Item
                style={{ width: "100%" }}
                name="name"
                hasFeedback={this.state.newNameError === "" ? false : true}
                help={this.state.newNameError}
                validateStatus={this.state.newNameError === "" ? null : "error"}
              >
                <Input
                  id="newName"
                  style={{ textAlign: "left" }}
                  placeholder="i.e John Doe"
                  onChange={this.handleChange}
                  value={this.state.newName}
                />
              </Form.Item>
            </VerticalContainer>
            <VerticalContainer
              style={{ width: "100%", alignItems: "flex-start" }}
            >
              <Label>{this.renderNewUserTitle(3)}</Label>
              <Form.Item
                style={{ width: "100%" }}
                name="phone"
                hasFeedback={this.state.newPhoneError === "" ? false : true}
                help={this.state.newPhoneError}
                validateStatus={
                  this.state.newPhoneError === "" ? null : "error"
                }
              >
                <Input
                  id="newPhone"
                  style={{ textAlign: "left" }}
                  placeholder="+60123456789"
                  onChange={this.handleChange}
                  value={this.state.newPhone}
                />
              </Form.Item>
            </VerticalContainer>
            <VerticalContainer
              style={{ width: "100%", alignItems: "flex-start" }}
            >
              <Label>{this.renderNewUserTitle(4)}</Label>
              <Form.Item
                style={{ width: "100%" }}
                name="State"
                hasFeedback={this.state.newStateError === "" ? false : true}
                help={this.state.newStateError}
                validateStatus={
                  this.state.newStateError === "" ? null : "error"
                }
              >
                <Select
                  id="state"
                  placeholder={this.renderNewUserTitle(9)}
                  onChange={this.handleSelectNewState}
                  value={this.state.newState}
                >
                  {this.renderStateSelection()}
                </Select>
              </Form.Item>
            </VerticalContainer>
            <VerticalContainer
              style={{ width: "100%", alignItems: "flex-start" }}
            >
              <Label>{this.renderNewUserTitle(5)}</Label>
              <Form.Item
                style={{ width: "100%" }}
                name="email"
                hasFeedback={this.state.newEmailError === "" ? false : true}
                help={this.state.newEmailError}
                validateStatus={
                  this.state.newEmailError === "" ? null : "error"
                }
              >
                <Input
                  id="newEmail"
                  type="email"
                  style={{ textAlign: "left" }}
                  placeholder="i.e example@gmail.com"
                  onChange={this.handleChange}
                  value={this.state.newEmail}
                />
              </Form.Item>
            </VerticalContainer>
            <VerticalContainer
              style={{ width: "100%", alignItems: "flex-start" }}
            >
              <Label>{this.renderNewUserTitle(13)}</Label>
              <Form.Item
                style={{ width: "100%" }}
                hasFeedback={this.state.newPasswordError === "" ? false : true}
                help={this.state.newPasswordError}
                validateStatus={
                  this.state.newPasswordError === "" ? null : "error"
                }
              >
                <Input
                  id="newPassword"
                  type="password"
                  style={{ textAlign: "left" }}
                  placeholder={this.renderNewUserTitle(13)}
                  onChange={this.handleChange}
                  value={this.state.newPassword}
                />
              </Form.Item>
            </VerticalContainer>
            <VerticalContainer
              style={{ width: "100%", alignItems: "flex-start" }}
            >
              <Label>{this.renderNewUserTitle(14)}</Label>
              <Form.Item
                style={{ width: "100%" }}
                hasFeedback={
                  this.state.newPasswordConfirmError === "" ? false : true
                }
                help={this.state.newPasswordConfirmError}
                validateStatus={
                  this.state.newPasswordConfirmError === "" ? null : "error"
                }
              >
                <Input
                  id="newPasswordConfirm"
                  type="password"
                  style={{ textAlign: "left" }}
                  placeholder={this.renderNewUserTitle(14)}
                  onChange={this.handleChange}
                  value={this.state.newPasswordConfirm}
                />
              </Form.Item>
            </VerticalContainer>
            <VerticalContainer
              style={{ width: "100%", alignItems: "flex-start" }}
            >
              <Label>{this.renderExistingUserTitle(16)}</Label>
              <Form.Item
                style={{ width: "100%" }}
                name="Device"
                hasFeedback={this.state.newDeviceError === "" ? false : true}
                help={this.state.newDeviceError}
                validateStatus={
                  this.state.newDeviceError === "" ? null : "error"
                }
              >
                <Select
                  id="newDevice"
                  placeholder={this.renderExistingUserTitle(16)}
                  onChange={this.handleSelectNewDevice}
                  value={this.state.newDevice}
                >
                  {this.renderDeviceSelection()}
                </Select>
              </Form.Item>
            </VerticalContainer>
            <VerticalContainer
              style={{ width: "100%", alignItems: "flex-start" }}
            >
              <Label>{this.renderNewUserTitle(6)}</Label>
              <Form.Item
                style={{ width: "100%" }}
                name="code"
                hasFeedback={this.state.newCodeError === "" ? false : true}
                help={this.state.newCodeError}
                validateStatus={this.state.newCodeError === "" ? null : "error"}
                required="true"
              >
                <Input
                  id="newCode"
                  type="code"
                  placeholder={this.renderNewUserTitle(8)}
                  style={{ textAlign: "left" }}
                  onChange={this.handleChange}
                  value={this.state.newCode}
                />
              </Form.Item>
            </VerticalContainer>
            <FlexibleRowContainer
              style={{ justifyContent: "center", marginTop: "1S0px" }}
            >
              <button
                style={{ background: BlueColor, fontSize: "24px" }}
                className="button"
                onClick={this.handleRegister.bind(this, "newUser")}
              >
                {this.renderNewUserTitle(7)}
              </button>
            </FlexibleRowContainer>
          </CustomForm>
        </VerticalFormContainer>
        <Divider />
        {this.renderExistingUserForm()}
      </FlexibleRowContainer>
    );
  };

  renderCongratulations = () => {
    return (
      <VerticalContainer style={{ margin: "50px 20px", minHeight: "75vh" }}>
        <img style={{ marginBottom: 5 }} src={doneIcon} />
        <Title1 style={{ color: BlueColor }}>
          {this.renderNewUserTitle(10)}
        </Title1>
        <Title1 style={{ color: BlueColor, textAlign: "center" }}>
          {this.renderNewUserTitle(11)}
        </Title1>
        <Title3 style={{ color: GreyColor, textAlign: "center" }}>
          {this.renderNewUserTitle(12)}
        </Title3>
        <img
          style={{
            height: this.props.utilsStore.deviceType === 0 ? 40 : 70,
            margin: "10px 0px",
          }}
          src={download}
        />
        <FlexibleRowContainer>
          {this.props.utilsStore.deviceType === 0 ? (
            <>
              <img src={applogo} style={{ margin: "0px 5px", height: 140 }} />
              <Headline href="https://apps.apple.com/us/app/id1510382298">
                <img
                  src={apple}
                  style={{ margin: "0px 5px", height: 50, marginBottom: 10 }}
                />
              </Headline>
            </>
          ) : (
            <>
              <Headline href="https://apps.apple.com/us/app/id1510382298">
                <img src={apple} style={{ margin: "0px 5px", height: 50 }} />
              </Headline>
              <img src={applogo} style={{ margin: "0px 5px", height: 140 }} />
            </>
          )}

          <Headline href="https://play.google.com/store/apps/details?id=co.mebooks.plus">
            <img src={playStore} style={{ margin: "0px 5px", height: 50 }} />
          </Headline>
        </FlexibleRowContainer>
      </VerticalContainer>
    );
  };

  render() {
    return (
      <VerticalContainer style={{ width: "100%" }}>
        <WindowResizer />
        <Header />
        {this.state.loading && (
          <LoadingContainer>
            <Spin size="large" />
            <Title5 style={{ color: BlueColor }}>Loading...</Title5>
          </LoadingContainer>
        )}
        {this.state.registered ? (
          <>{this.renderCongratulations()}</>
        ) : (
          <VerticalContainer style={{ width: "90%", maxWidth: 1600 }}>
            <Title1
              style={{ color: BlueColor, textAlign: "center", marginTop: 30 }}
            >
              {this.renderExistingUserTitle(8)}
            </Title1>
            <Title3 style={{ color: GreyColor, textAlign: "center" }}>
              {this.renderExistingUserTitle(9)}
            </Title3>
            <Title3 style={{ color: GreyColor, textAlign: "center" }}>
              {this.renderExistingUserTitle(10)}
            </Title3>
            {this.props.utilsStore.deviceType === 0 && (
              <VerticalContainer style={{ marginTop: 30 }}>
                <Headline href="#new">
                  <button
                    style={{
                      background: BlueColor,
                      fontSize: "18px",
                      width: 280,
                      height: 45,
                      margin: "5px 0px",
                    }}
                    className="button"
                  >
                    {this.renderNewUserTitle(0)}
                  </button>
                </Headline>
                <Headline href="#existing">
                  <button
                    href="#existing"
                    style={{
                      background: BlueColor,
                      fontSize: "18px",
                      width: 280,
                      height: 45,
                      margin: "5px 0px",
                    }}
                    className="button"
                  >
                    {this.renderExistingUserTitle(0)}
                  </button>
                </Headline>
              </VerticalContainer>
            )}
            {this.renderNewUserForm()}
          </VerticalContainer>
        )}
        <Footer />
      </VerticalContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authStore: state.authStore,
    utilsStore: state.utilsStore,
  };
};

export default connect(mapStateToProps, null)(Register);

const LoadingContainer = styled(VerticalContainer)`
  z-index: 5;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  background: rgba(247, 247, 252, 0.95);
`;

const Divider = styled.div`
  border: 1px solid #939393;
  background-color: #939393;
  height: 1000px;
  margin: 0px 25px;

  @media only screen and (max-width: ${minTabletBreakpoints - 1}px) {
    display: none;
  }
`;

const VerticalFormContainer = styled(VerticalContainer)`
  align-items: flex-start;
  padding: 5px;
  width: 40%;

  @media only screen and (max-width: ${minTabletBreakpoints - 1}px) {
    width: 100%;
    align-items: center;
    margin-bottom: 30px;
  }
`;

const Label = styled(Title6)`
  color: ${BlueColor};
`;

const CustomForm = styled(Form)`
  margin-bottom: 40px;
  margin-top: 15px;
  width: 100%;
`;

const FormSubtitle = styled(Title3)`
  font-family: visbyRoundBold;
  color: ${BlueColor} !important;
  font-size: 23px;
  text-align: left;

  @media only screen and (max-width: ${minMobileBreakpoints - 1}px) {
    font-size: 18px;
    text-align: center;
  }
`;

const FormDescription = styled(Title3)`
  text-align: center;
  color: ${GreyColor} !important;
  text-align: left;

  @media only screen and (max-width: ${minMobileBreakpoints - 1}px) {
    text-align: center;
  }
`;
