const errorList = [
  {
    key: "0",
    message: "Email Error",
    description:
      "The email cannot be found. Please recheck that you have entered the correct email",
    messageTranslation: "Emel Tidak Sah",
    descriptionTranslation:
      "Emel ini tidak dapat dikesan. Sila semak semula bahawa anda telah memasukkan emel yang betul",
  },
  {
    key: "1",
    message: "Code Error",
    description:
      "This gift code cannot be found. Please recheck that you have entered the correct gift code",
    messageTranslation: "Kod Tak Sah",
    descriptionTranslation:
      "Kod hadiah ini tidak dapat dikesan. Sila semak semula bahawa anda telah memasukkan kod hadiah yang betul.",
  },
  {
    key: "2",
    message: "Code Error",
    description: "This gift code has been used. Please try other gift code",
    messageTranslation: "Kod Tak Sah",
    descriptionTranslation:
      "Kod ini telah diguna. Sila gunakan kod hadiah yang lain. ",
  },
  {
    key: "3",
    message: "Unknown Error",
    description:
      "We are unsure with what error you are facing, if this problem still exists, please contact hello@mebooks.co",
    messageTranslation: "Kod Tidak Ketahui",
    descriptionTranslation:
      "Kami tidak pasti dengan masalah yang anda sedang hadapi, jika masalah ini berterusan, sila hubungi hello@mebooks.co",
  },
  {
    key: "4",
    message: "User Existed",
    description:
      "This email account has been created. Please try a new email address",
    messageTranslation: "E-mel Diguna",
    descriptionTranslation:
      "Akaun e-mel ini telah diguna. Sila cuba e-mel baru",
  },
];

export default errorList;
