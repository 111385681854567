const footerList = [
  {
    key: "0",
    title: '"We Believe in Our Children"',
    titleTranslation: '"Kami Percaya Pada Kanak-Kanak Kami"',
  },
  {
    key: "1",
    title: "About Me Books",
    titleTranslation: "Tentang Me Books",
  },
  {
    key: "2",
    title: "Contact Us",
    titleTranslation: "Hubungi Kami",
  },
  {
    key: "3",
    title: "Privacy Policy",
    titleTranslation: "Dasar Privasi",
  },
  {
    key: "4",
    title: "Available At",
    titleTranslation: "Ada Di",
  },
  {
    key: "5",
    title: "User Terms and Conditions",
    titleTranslation: "Terma dan Syarat Pengguna",
  },
];

export default footerList;
