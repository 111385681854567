import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
//Styles
import { GreenColor } from "../utils/Color";
import {
  RowContainer,
  VerticalContainer,
  FlexibleRowContainer,
  minTabletBreakpoints,
} from "./Layout";
import {
  Title4,
  Title5,
  Title6,
  Body,
  Headline,
} from "../components/Typography";
//Assets
import applogo from "../assets/app-theatre-logo-new.png";
import appStore from "../assets/app-store.png";
import playStore from "../assets/play-store.png";
//Utils
import footerList from "../utils/FooterTranslation";

const footerMargin = 10;

class Footer extends Component {
  renderTitle = (key) => {
    let title = footerList[key].title;
    if (this.props.authStore && this.props.authStore.userLanguage === "BM")
      title = footerList[key].titleTranslation;
    return title;
  };

  render() {
    return (
      <FooterContainer>
        <CustomVerticalContainer>
          <Title4>{this.renderTitle(0)}</Title4>
          <RowContainer style={{ marginTop: 5, marginBottom: 8 }}>
            <Headline href="https://www.mebooks.co/about" target="_blank">
              <Title5 style={{ marginRight: footerMargin }}>
                {this.renderTitle(1)}
              </Title5>
            </Headline>
            <Title5 style={{ marginRight: footerMargin }}>|</Title5>
            <Headline href="https://www.mebooks.co/contact-us" target="_blank">
              <Title5>{this.renderTitle(2)}</Title5>
            </Headline>
          </RowContainer>
          <FlexibleRowContainer>
            <Body>© 2020-21 Me Books Asia Sdn Bhd.&nbsp;</Body>
            <RowContainer
              style={{
                marginTop: this.props.utilsStore.deviceType === 0 ? 5 : 0,
              }}
            >
              <Headline
                style={{ textDecoration: "underline" }}
                href="https://www.mebooks.co/privacy"
              >
                {this.renderTitle(3)}
              </Headline>
              <Headline
                style={{ marginLeft: 10, textDecoration: "underline" }}
                href="https://www.mebooks.co/terms-conditions"
              >
                {this.renderTitle(5)}
              </Headline>
            </RowContainer>
          </FlexibleRowContainer>
        </CustomVerticalContainer>
        <FlexibleRowContainer
          style={{ marginTop: this.props.utilsStore.deviceType === 0 ? 30 : 0 }}
        >
          <img
            src={applogo}
            style={{
              height: 90,
              marginRight: this.props.utilsStore.deviceType === 0 ? 0 : 20,
            }}
          />
          <CustomVerticalContainer>
            <Title6
              style={{
                marginBottom: this.props.utilsStore.deviceType === 0 ? 5 : 0,
              }}
            >
              {this.renderTitle(4)}
            </Title6>
            <RowContainer>
              <Headline href="https://apps.apple.com/us/app/id1510382298">
                <StoreLogo style={{ marginRight: 5 }} src={appStore} />
              </Headline>
              <Headline href="https://play.google.com/store/apps/details?id=co.mebooks.plus">
                <StoreLogo src={playStore} />
              </Headline>
            </RowContainer>
          </CustomVerticalContainer>
        </FlexibleRowContainer>
      </FooterContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authStore: state.authStore,
    utilsStore: state.utilsStore,
  };
};

export default connect(mapStateToProps, null)(Footer);

const CustomVerticalContainer = styled(VerticalContainer)`
  align-items: flex-start;

  @media only screen and (max-width: ${minTabletBreakpoints - 1}px) {
    align-items: center;
  }
`;

const FooterContainer = styled(FlexibleRowContainer)`
  width: 100%;
  background-color: ${GreenColor};
  justify-content: space-around;
  padding: 25px 0px;
`;

const StoreLogo = styled.img`
  height: 35px;
  cursor: pointer;
`;
