export default [
  {
    id: 1,
    value: "SGR",
    label: "Selangor",
  },
  {
    id: 2,
    value: "KUL",
    label: "Kuala Lumpur",
  },
  {
    id: 3,
    value: "JHR",
    label: "Johor",
  },
  {
    id: 4,
    value: "SWK",
    label: "Sarawak",
  },
  {
    id: 5,
    value: "SBH",
    label: "Sabah",
  },
  {
    id: 6,
    value: "PRK",
    label: "Perak",
  },
  {
    id: 7,
    value: "PNG",
    label: "Penang",
  },
  {
    id: 8,
    value: "KDH",
    label: "Kedah",
  },
  {
    id: 9,
    value: "PHG",
    label: "Pahang",
  },
  {
    id: 11,
    value: "NSN",
    label: "Negeri Sembilan",
  },
  {
    id: 12,
    value: "KTN",
    label: "Kelantan",
  },
  {
    id: 13,
    value: "TRG",
    label: "Terengganu",
  },
  {
    id: 14,
    value: "MLK",
    label: "Melaka",
  },
  {
    id: 15,
    value: "PJY",
    label: "Putrajaya",
  },
  {
    id: 16,
    value: "PLS",
    label: "Perlis",
  },
  {
    id: 17,
    value: "LBN",
    label: "Labuan",
  },
];
