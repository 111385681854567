import { combineReducers } from "redux";
import authReducer from "./authReducer";
import utilsReducer from "./utilsReducer";

const rootReducer = combineReducers({
  authStore: authReducer,
  utilsStore: utilsReducer,
});

export default rootReducer;
