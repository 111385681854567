const translationList = [
  {
    key: "0",
    image: require("../assets/finding-little-moo-1.png"),
    imageTranslation: require("../assets/cari-si-sopi-1.png"),
  },
  {
    key: "1",
    image: require("../assets/finding-little-moo-2.png"),
    imageTranslation: require("../assets/cari-si-sopi-2.png"),
  },
  {
    key: "2",
    image: require("../assets/finding-little-moo-3.png"),
    imageTranslation: require("../assets/cari-si-sopi-3.png"),
  },
  {
    key: "3",
    image: require("../assets/a-place-called-home-1.png"),
    imageTranslation: require("../assets/planet-rumah-kami-1.png"),
  },
  {
    key: "4",
    image: require("../assets/a-place-called-home-2.png"),
    imageTranslation: require("../assets/planet-rumah-kami-2.png"),
  },
  {
    key: "5",
    image: require("../assets/a-place-called-home-3.png"),
    imageTranslation: require("../assets/planet-rumah-kami-3.png"),
  },
  {
    key: "6",
    image: require("../assets/eng-storybooks-1.png"),
    imageTranslation: require("../assets/bm-storybooks-1.png"),
  },
  {
    key: "7",
    image: require("../assets/eng-storybooks-2.png"),
    imageTranslation: require("../assets/bm-storybooks-2.png"),
  },
];

export default translationList;
