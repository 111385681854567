import React, { Component } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
//Styles
import { GiHamburgerMenu } from "react-icons/gi";
import { RowContainer, VerticalContainer } from "./Layout";
import { BlueColor, WhiteColor } from "../utils/Color";
import { Title1, Title3, Title4, Headline } from "../components/Typography";
//Assets
import logo from "../assets/dl-logo.png";
import ratVector from "../assets/rat.png";
import logoLineUps from "../assets/logo-line-ups.png";
//Actions
import { updateLanguagePreferences } from "../store/actions/authActions";
//Utils
import headerList from "../utils/HeaderTranslation";

const langMargin = 10;
const itemMargin = 30;
class Header extends Component {
  state = {
    menuBarOpen: false,
  };

  handleMenuOpen = (bool) => {
    this.setState({
      menuBarOpen: bool,
    });
  };

  handleLanguagePreferences = (language) => {
    this.props.updateLanguagePreferences(language);
  };

  renderTitle = (key) => {
    let title = headerList[key].title;
    if (this.props.authStore && this.props.authStore.userLanguage === "BM")
      title = headerList[key].titleTranslation;
    return title;
  };

  renderMenuItems = () => {
    return (
      <>
        <Link to="/book">
          <MenuLink style={{ marginRight: itemMargin }}>
            {this.renderTitle(0)}
          </MenuLink>
        </Link>
        <Headline href="https://www.mebooks.co/about" target="_blank">
          <MenuLink style={{ marginRight: itemMargin }}>
            {this.renderTitle(1)}
          </MenuLink>
        </Headline>
        <Headline href="https://www.mebooks.co/contact-us" target="_blank">
          <MenuLink style={{ marginRight: itemMargin }}>
            {this.renderTitle(2)}
          </MenuLink>
        </Headline>
      </>
    );
  };

  renderLanguageSelection = () => {
    return (
      <>
        <MenuLink
          style={
            this.props.authStore.userLanguage !== "EN" ? { color: "#aaa" } : {}
          }
          onClick={this.handleLanguagePreferences.bind(this, "EN")}
        >
          EN
        </MenuLink>
        <Title4 style={{ marginLeft: langMargin, marginRight: langMargin }}>
          |
        </Title4>
        <MenuLink
          style={
            this.props.authStore.userLanguage !== "BM" ? { color: "#aaa" } : {}
          }
          onClick={this.handleLanguagePreferences.bind(this, "BM")}
        >
          BM
        </MenuLink>
      </>
    );
  };

  renderMenu = () => {
    if (this.props.utilsStore.deviceType <= 1)
      return <HamburgerIcon onClick={this.handleMenuOpen.bind(this, true)} />;
    else
      return (
        <RowContainer>
          {this.renderMenuItems()}
          {this.renderLanguageSelection()}
        </RowContainer>
      );
  };

  renderMobileMenu = () => {
    return (
      <Overlay>
        <Title1
          onClick={this.handleMenuOpen.bind(this, false)}
          style={{ marginTop: 20, alignSelf: "flex-end" }}
        >
          X
        </Title1>
        {this.renderMenuItems()}
        <RowContainer>{this.renderLanguageSelection()}</RowContainer>
        <RowContainer style={{ width: "100%", marginTop: itemMargin }}>
          <img src={logoLineUps} style={{ width: 300 }} />
        </RowContainer>
        <RowContainer
          style={{
            width: "100%",
            marginTop: -20,
            justifyContent: "flex-end",
          }}
        >
          <img src={ratVector} />
        </RowContainer>
      </Overlay>
    );
  };

  render() {
    return (
      <>
        {this.props.utilsStore.deviceType <= 1 &&
          this.state.menuBarOpen &&
          this.renderMobileMenu()}
        <RowContainer style={{ width: "100%", backgroundColor: BlueColor }}>
          <HeaderContainer>
            <Link to="/">
              <img style={{ height: 90 }} src={logo} />
            </Link>
            <div style={{ flexGrow: 1 }} />
            {this.renderMenu()}
          </HeaderContainer>
        </RowContainer>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authStore: state.authStore,
    utilsStore: state.utilsStore,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateLanguagePreferences: (language) =>
      dispatch(updateLanguagePreferences(language)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);

const HeaderContainer = styled(RowContainer)`
  height: 80px;
  width: 85%;
`;

const Overlay = styled(VerticalContainer)`
  z-index: 5;
  overflow-y: auto;
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 30px;
  background: ${BlueColor};
`;

const HamburgerIcon = styled(GiHamburgerMenu)`
  font-size: 30px;
  cursor: pointer;
  color: ${WhiteColor};
`;

const MenuLink = styled(Title3)`
  cursor: pointer;
`;
