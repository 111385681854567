const headerList = [
  {
    key: "0",
    title: "Books",
    titleTranslation: "Buku-buku",
  },
  {
    key: "1",
    title: "What’s Me Books?",
    titleTranslation: "Tentang Me Books",
  },
  {
    key: "2",
    title: "Help?",
    titleTranslation: "Bantuan?",
  },
];

export default headerList;
