const errorList = [
  {
    key: "0",
    title: "Please enter the correct email format",
    titleTranslation: "Sila masukkan format e-mel yang betul",
  },
  {
    key: "1",
    title: "Please enter your gift code",
    titleTranslation: "Sila masukkan kod hadiah anda",
  },
  {
    key: "2",
    title: "Please enter your name",
    titleTranslation: "Sila masukkan nama anda",
  },
  {
    key: "3",
    title: "Please select your state",
    titleTranslation: "Sila pilih negeri anda",
  },
  {
    key: "4",
    title: "Please enter the correct phone format",
    titleTranslation: "Sila masukkan format telefon yang betul",
  },
  {
    key: "5",
    title: "Please enter a valid password",
    titleTranslation: "Sila masukkan kata laluan yang sah",
  },
  {
    key: "6",
    title: "Please enter a same password",
    titleTranslation: "Sila masukkan kata laluan yang sama",
  },
  {
    key: "7",
    title: "Please select your device",
    titleTranslation: "Sila pilih aplikasi anda",
  },
  {
    key: "8",
    title: "Please enter a valid code from Dutch Lady",
    titleTranslation: "Sila masukkan kod yang sah daripada Dutch Lady",
  },
];

export default errorList;
