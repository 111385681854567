import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
//Styles
import { BlueColor, GreyColor } from "../utils/Color";
import { VerticalContainer } from "../components/Layout";
import { Title1, Title2 } from "../components/Typography";
//Components
import Header from "../components/Header";
import Footer from "../components/Footer";
//Assets
import applogo from "../assets/app-theatre-logo-coloured-new.png";
//Utils
import noticeList from "../utils/NoticeTranslation";

class Notice extends Component {
  state = {
    noticeList: noticeList,
  };

  handleDownloadAndroid = () => {
    window.open(
      "https://drive.google.com/file/d/1oQsEYAG0VIt-_L3rVZgPvGJcWtOjAfSV/view?usp=sharing"
    );
  };

  renderTitle = (key) => {
    let title = this.state.noticeList[key].title;
    if (this.props.authStore && this.props.authStore.userLanguage === "BM")
      title = this.state.noticeList[key].titleTranslation;
    return title;
  };

  render() {
    return (
      <RootContainer>
        <Header />
        <CustomVerticalContainer>
          <img src={applogo} style={{ margin: "0px 5px", height: 180 }} />
          <Title>{this.renderTitle(0)}</Title>
          <Title>{this.renderTitle(1)}</Title>
          <Desc>{this.renderTitle(2)}</Desc>
          <br />
          <br />
          <DescAndroid>{this.renderTitle(3)}</DescAndroid>
          <DescAndroid>{this.renderTitle(4)}</DescAndroid>
          <DescAndroid>{this.renderTitle(6)}</DescAndroid>
          <DescAndroid>{this.renderTitle(7)}</DescAndroid>
          <DescAndroid>{this.renderTitle(8)}</DescAndroid>
          <button
            style={{
              background: BlueColor,
              marginTop: "15px",
              height: "auto",
            }}
            className="button"
            onClick={this.handleDownloadAndroid}
          >
            {this.renderTitle(5)}
          </button>
          <br />
        </CustomVerticalContainer>
        <Footer />
      </RootContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authStore: state.authStore,
  };
};

export default connect(mapStateToProps, null)(Notice);

const Title = styled(Title1)`
  color: ${BlueColor} !important;
  text-align: center;
  line-height: initial;
`;

const DescAndroid = styled(Title2)`
  text-align: left;
  color: ${GreyColor} !important;
  width: 90%;
`;

const Desc = styled(Title2)`
  text-align: center;
  color: ${GreyColor} !important;
  width: 90%;
`;

const CustomVerticalContainer = styled(VerticalContainer)`
  width: 100%;
  height: auto;

  @media only screen and (max-width: 480px) {
    height: auto;
  }
`;

const RootContainer = styled.div`
  height: auto;
`;
