const registerExistingList = [
  {
    key: "0",
    title: "For Existing Me Books Users",
    titleTranslation: "Untuk Pengguna Sedia Ada Me Books",
  },
  {
    key: "1",
    title: "Add to Your Existing Account",
    titleTranslation: "Tambah ke Akaun Sedia Ada Anda!",
  },
  {
    key: "2",
    title: "Name",
    titleTranslation: "Nama",
  },
  {
    key: "3",
    title: "Contact",
    titleTranslation: "Nombor telefon",
  },
  {
    key: "4",
    title: "State",
    titleTranslation: "Negeri (Pilih Negeri)",
  },
  {
    key: "5",
    title: "Email Address",
    titleTranslation: "E-mel",
  },
  {
    key: "6",
    title: "Gift Code",
    titleTranslation: "Kad Hadiah (Masuk Penebusan Kod)",
  },
  {
    key: "7",
    title: "Add to Account",
    titleTranslation: "Tambah ke Akaun",
  },
  {
    key: "8",
    title: "Register A New Me Books Account",
    titleTranslation: "Daftar Me Books Akaun Baharu",
  },
  {
    key: "9",
    title: "Insert the promotional code or Gift Card.",
    titleTranslation: "Masukkan kod promosi untuk Kad Hadiah.",
  },
  {
    key: "10",
    title: "The code will be applied to your registered Me Books Email.",
    titleTranslation:
      "Kod tersebut akan digunakan pada E-Mel Me Books anda yang berdaftar",
  },
  {
    key: "11",
    title:
      "If you are currently subscribed through Apple, you will need to turn off Apple’s auto-renew.",
    titleTranslation:
      "Jika andai telah melanggan melalui Apple kini, anda perlu mematikan pembaharuan automatik Apple. Ketahui bagaimana.",
  },
  {
    key: "12",
    title: " Learn how.",
    titleTranslation: "Ketahui bagaimana.",
  },
  {
    key: "13",
    title: "Enter Redemption Code",
    titleTranslation: "Masuk Penebusan Kod",
  },
  {
    key: "14",
    title: "Select State",
    titleTranslation: "Pilih Negeri",
  },
  {
    key: "15",
    title: "Password",
    titleTranslation: "Kata Laluan",
  },
  {
    key: "16",
    title: "Select Device",
    titleTranslation: "Pilih Aplikasi",
  },
];

export default registerExistingList;
