export const updateLanguagePreferences = (language) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: "UPDATE_USER_LANG",
        payload: {
          userLanguage: language,
        },
      });
    } catch (err) {}
  };
};
