const translationList = [
  {
    key: "0",
    title: "English",
    titleTranslation: "Bahasa Inggeris",
  },
  {
    key: "1",
    title: "4-6 years old",
    titleTranslation: "4-6 tahun",
  },
  {
    key: "2",
    title: "Curiousity",
    titleTranslation: "Keinginan tahu",
  },
  {
    key: "3",
    title: "Me Books Creations",
    titleTranslation: "Ciptaan Me Books",
  },
  {
    key: "4",
    title: "Finding Little Moo",
    titleTranslation: "Misi Mencari Si Sopi",
  },
  {
    key: "5",
    title: "Written By: Charmaine Low",
    titleTranslation: "Ditulis oleh: Charmaine Low",
  },
  {
    key: "6",
    title: "Illustrated By: Ang Hui Qing",
    titleTranslation: "Diilustrasikan oleh: Ang Hui Qing",
  },
  {
    key: "7",
    title:
      "While Adam and Sofia were playing outdoors, they stumbled upon an unexpected surprise that rustled in the bushes.",
    titleTranslation:
      "Adam dan Sofia adalah adik beradik yang melalui pengalaman yang tidak diduga semasa mereka keluar bermain, ini adalah kisah mereka. Namun, seekor haiwan bernama Sopi terluka dirinya.",
  },
  {
    key: "8",
    title:
      "Find out what lurked in the bushes that sparked the beginning of a new friendship. This book is for everyone who seeks an adventure to hang onto!",
    titleTranslation:
      "Bagaimanakah Adam dan Sofia mampu untuk menyembuh Si Sopi? Jom kita ikuti mereka!",
  },
  {
    key: "9",
    title: "A Place Called Home",
    titleTranslation: "Planet Itu Rumah Kami",
  },
  {
    key: "10",
    title:
      " A story of two siblings names Adam and Sofia who have seen some wonders of the sky but of all the wonders that they have come across, will any of it compare to planet Earth? Stick around to find out!",
    titleTranslation:
      "Sebuah kisah dua-beradik yang bernama Adam dan Sofia, telah melihat berberapa keajaiban langit tetapi daripada semua keajaiban yang mereka telah menerokai, adakah kesemua itu dapat dibandingkan dengan planet Bumi? Ikutilah untuk mengetahui!",
  },
];

export default translationList;
