const homeList = [
  {
    key: "0",
    title: "Extra value for your little ones!",
    titleTranslation: "Dapatkan nilai lebih untuk anak-anak anda!",
  },
  {
    key: "1",
    title: "Register to experience FREE children’s Audio books",
    titleTranslation:
      "Daftar untuk menikmati buku audio kanak-kanak secara percuma",
  },
  {
    key: "2",
    title: '"Planet itu Rumah Kami" & "Misi Mencari Si Sopi"!',
    titleTranslation: "iaitu “Planet itu Rumah kami” & “Misi mencari Si Sopi!”",
  },
  {
    key: "3",
    title: "Register Now",
    titleTranslation: "Daftar sekarang",
  },
  {
    key: "4",
    title: "Play Video",
    titleTranslation: "Mainkan video",
  },
  {
    key: "5",
    title: "Learn More",
    titleTranslation: "Ketahui Lebih Lanjut",
  },
  {
    key: "6",
    title: "Find us on",
    titleTranslation: "Cari kami di",
  },
  {
    key: "7",
    title: "Tutorial",
    titleTranslation: "Tutorial",
  },
];

export default homeList;
