const initState = {
  userLanguage: "EN",
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case "UPDATE_USER_LANG":
      return {
        ...state,
        userLanguage: action.payload.userLanguage,
      };
    default:
      return state;
  }
};

export default authReducer;
