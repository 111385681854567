const noticeList = [
  {
    key: "0",
    title: "Me Books App",
    titleTranslation: "Aplikasi Me Books",
  },
  {
    key: "1",
    title: "Android",
    titleTranslation: "Android",
  },
  {
    key: "2",
    title:
      "Enjoy 3 months free access of more than 300 interactive audiobook titles!",
    titleTranslation:
      "Nikmati akses percuma selama 3 bulan dengan lebih daripada 300 tajuk buku audio interaktif!",
  },
  {
    key: "3",
    title: "Steps to download:",
    titleTranslation: "Langkah-langkah untuk muat turun:",
  },
  {
    key: "4",
    title: "1. Click on the download button below",
    titleTranslation: "1. Klik butang muat turun di bawah.",
  },
  {
    key: "5",
    title: "Download",
    titleTranslation: "Muat Turun",
  },
  {
    key: "6",
    title:
      "2. Allow Google Drive/Web Browser to install Me Books App when security message is prompted",
    titleTranslation:
      "2. Benarkan Google Drive/Laman Web untuk muat turun aplikasi Me Books apabila mesej sekuriti dikeluarkan.",
  },
  {
    key: "7",
    title:
      "3. Launch downloaded Me Books Application and follow its installation procedures",
    titleTranslation:
      "3. Lancarkan Aplikasi Me Books yang sudah dimuat turun dan ikut procedur pemasangannya.",
  },
  {
    key: "8",
    title: "4. Enjoy Me Books App!",
    titleTranslation: "4. Nikmati Aplikasi Me Books!",
  },
];

export default noticeList;
