import React, { Component } from "react";
import { connect } from "react-redux";
//Actions
import {
  updateDeviceType,
  updateDeviceSize,
} from "../store/actions/utilsActions";
//Utils
import { minLaptopBreakpoints, minTabletBreakpoints } from "./Layout";

class WindowResizer extends Component {
  componentDidMount() {
    this.handleCalculateWidth();
    window.addEventListener("resize", this.handleCalculateWidth);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleCalculateWidth);
  }

  handleCalculateWidth = () => {
    let deviceType = 2;
    if (window.innerWidth < minTabletBreakpoints) deviceType = 0;
    else if (window.innerWidth < minLaptopBreakpoints) deviceType = 1;
    else deviceType = 2;

    if (deviceType !== this.props.utilsStore.deviceType)
      this.props.updateDeviceType(deviceType);
    if (window.innerWidth !== this.props.utilsStore.deviceSize)
      this.props.updateDeviceSize(window.innerWidth);
  };

  render() {
    return null;
  }
}

const mapStateToProps = (state) => {
  return {
    utilsStore: state.utilsStore,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateDeviceType: (deviceType) => dispatch(updateDeviceType(deviceType)),
    updateDeviceSize: (deviceSize) => dispatch(updateDeviceSize(deviceSize)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WindowResizer);
