import styled from "styled-components";
import { minMobileBreakpoints } from "./Layout";

export const Title1 = styled.h1`
  font-family: "doctorSoosBold";
  font-size: 46px;
  margin-bottom: 0px;
  color: #ffffff;

  @media only screen and (max-width: ${minMobileBreakpoints - 1}px) {
    font-size: 36px;
  }
`;

export const Title2 = styled.h2`
  font-family: doctorSoosBold;
  font-size: 28px;
  margin-bottom: 0px;
  color: #ffffff;

  @media only screen and (max-width: ${minMobileBreakpoints - 1}px) {
    font-size: 22px;
  }
`;

export const Title3 = styled.h3`
  font-family: "doctorSoosBold";
  font-size: 24px;
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 0px;

  @media only screen and (max-width: ${minMobileBreakpoints - 1}px) {
    font-size: 20px;
  }
`;

export const Title4 = styled.h4`
  font-family: museo_sans_rounded900;
  font-size: 20px;
  color: #ffffff;
  margin-bottom: 0px;

  @media only screen and (max-width: ${minMobileBreakpoints - 1}px) {
    font-size: 18px;
  }
`;

export const Title5 = styled.h5`
  font-family: museo_sans_rounded900;
  font-size: 18px;
  color: #ffffff;
  margin-bottom: 0px;

  @media only screen and (max-width: ${minMobileBreakpoints - 1}px) {
    font-size: 16px;
  }
`;

export const Title6 = styled.h6`
  font-family: museo_sans_rounded900;
  font-size: 16px;
  color: #ffffff;
  margin-bottom: 0px;

  @media only screen and (max-width: ${minMobileBreakpoints - 1}px) {
    font-size: 14px;
  }
`;

export const Body = styled.p`
  font-family: museo_sans_rounded300;
  font-size: 12px;
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 0px;
`;

export const Headline = styled.a`
  font-family: museo_sans_rounded300;
  font-size: 12px;
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 0px;

  &:hover {
    color: #ffffff;
  }
`;
