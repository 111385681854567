import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
//Styles
import { LightGreyColor } from "../utils/Color";
import {
  VerticalContainer,
  FlexibleRowContainer,
  FlexibleVerticalContainer,
  RowContainer,
  minTabletBreakpoints,
} from "../components/Layout";
import { Title1, Title2, Title4, Headline } from "../components/Typography";
import WindowResizer from "../components/WindowResizer";
//Components
import Header from "../components/Header";
import Footer from "../components/Footer";
//Assets
import Background from "../assets/home-background-wide.png";
import Storybooks from "../assets/new-story-book.png";
import FBIcon from "../assets/fb.png";
import WebIcon from "../assets/web.png";
import applogo from "../assets/app-theatre-logo-new.png";
//Utils
import homeList from "../utils/HomeTranslation";

class Home extends Component {
  state = {
    currentList: homeList,
    modalVisible: false,
    playing: false,
  };

  handleShowModal = () => {
    this.setState({
      modalVisible: true,
      playing: true,
    });
  };

  handleHideModal = () => {
    this.setState({
      modalVisible: false,
      playing: false,
    });
    this.player.seekTo(0);
  };

  handleNextPage = () => {
    this.props.history.push("/register");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  renderTitle = (key) => {
    let title = this.state.currentList[key].title;
    if (this.props.authStore && this.props.authStore.userLanguage === "BM")
      title = this.state.currentList[key].titleTranslation;
    return title;
  };

  render() {
    return (
      <>
        <WindowResizer />
        <Header />
        <Content style={{ backgroundImage: "url(" + Background + ")" }}>
          <FlexibleRowContainer style={{ width: "95%" }}>
            <img
              style={{ width: "35%", maxWidth: 450, minWidth: 300 }}
              src={Storybooks}
            />
            <CustomVerticalContainer>
              <img src={applogo} style={{ height: 100 }} />
              <Title1
                style={{
                  textAlign:
                    this.props.utilsStore.deviceType < 1 ? "center" : "left",
                }}
              >
                {this.renderTitle(0)}
              </Title1>
              <Title2
                style={{
                  color: LightGreyColor,
                  textAlign:
                    this.props.utilsStore.deviceType < 1 ? "center" : "left",
                }}
              >
                {this.renderTitle(1)}
                {this.props.utilsStore.deviceType === 2 && <br />}
                {this.renderTitle(2)}
              </Title2>
              <RowContainer>
                <button
                  style={{
                    marginTop: "15px",
                  }}
                  className="button"
                  onClick={this.handleNextPage}
                >
                  {this.renderTitle(3)}
                </button>
                <Headline href="https://youtu.be/pVmqgt9Ze0c">
                  <button
                    style={{
                      marginTop: "15px",
                      height: "auto",
                      marginLeft: 10,
                    }}
                    className="button"
                  >
                    {this.renderTitle(4)}
                  </button>
                </Headline>
              </RowContainer>
            </CustomVerticalContainer>
          </FlexibleRowContainer>
          <FlexibleVerticalContainer style={{ marginTop: 50 }}>
            <Title4>{this.renderTitle(6)}</Title4>
            <RowContainer>
              <Headline
                href="https://www.facebook.com/DutchLady.SmartMoments"
                target="_blank"
              >
                <img
                  src={FBIcon}
                  style={{
                    margin: 5,
                  }}
                />
              </Headline>
              <Headline href="https://www.smartmoments.com.my/" target="_blank">
                <img
                  src={WebIcon}
                  style={{
                    margin: 5,
                  }}
                />
              </Headline>
            </RowContainer>
          </FlexibleVerticalContainer>
        </Content>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authStore: state.authStore,
    utilsStore: state.utilsStore,
  };
};

export default connect(mapStateToProps, null)(Home);

const Content = styled(VerticalContainer)`
  height: 85vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  @media only screen and (max-width: ${minTabletBreakpoints - 1}px) {
    height: auto;
    padding-top: 50px;
    padding-bottom: 50px;
  }
`;

const CustomVerticalContainer = styled(VerticalContainer)`
  align-items: flex-start;

  @media only screen and (max-width: ${minTabletBreakpoints - 1}px) {
    align-items: center;
  }
`;
