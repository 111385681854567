const registerList = [
  {
    key: "0",
    title: "For New Users",
    titleTranslation: "Untuk Pengguna Baharu",
  },
  {
    key: "1",
    title: "Redeem Your Gift Subscription",
    titleTranslation: "Tebuskan Langgangan Hadiah Anda",
  },
  {
    key: "2",
    title: "Name",
    titleTranslation: "Nama",
  },
  {
    key: "3",
    title: "Contact",
    titleTranslation: "Nombor telefon",
  },
  {
    key: "4",
    title: "State",
    titleTranslation: "Negeri (Pilih Negeri)",
  },
  {
    key: "5",
    title: "Email Address",
    titleTranslation: "E-mel",
  },
  {
    key: "6",
    title: "Gift Code",
    titleTranslation: "Kad Hadiah (Masuk Penebusan Kod)",
  },
  {
    key: "7",
    title: "Redeem",
    titleTranslation: "Tebus",
  },
  {
    key: "8",
    title: "Enter Redemption Code",
    titleTranslation: "Masuk Penebusan Kod",
  },
  {
    key: "9",
    title: "Select State",
    titleTranslation: "Pilih Negeri",
  },
  {
    key: "10",
    title: "Congratulations",
    titleTranslation: "Tahniah!",
  },
  {
    key: "11",
    title: "Your Redemption is Successful",
    titleTranslation: "Tebusan anda telah berjaya!",
  },
  {
    key: "12",
    title: "You have 3 months Unlimited Subscription Now.",
    titleTranslation:
      "Anda mempunyai langgangan tanpa had selama tiga bulan mulai sekarang.",
  },
  {
    key: "13",
    title: "Create Password",
    titleTranslation: "Cipta Kata Laluan",
  },
  {
    key: "14",
    title: "Confirm Password",
    titleTranslation: "Sahkan Kata Laluan",
  },
];

export default registerList;
