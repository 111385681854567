import React, { Component } from "react";
//Navigator
import Navigator from "../src/navigator/Navigator";
//Redux Store
import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "../src/store/reducers/rootReducer";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
//Utils
import ReactGA from "react-ga";

//Store Creation
const initialState = {};
const middleware = [thunk];
const store = createStore(
  rootReducer,
  initialState,
  compose(applyMiddleware(...middleware))
);

class App extends Component {
  componentDidMount() {
    ReactGA.initialize("UA-145714156-5");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render() {
    return (
      <Provider store={store}>
        <Navigator />
      </Provider>
    );
  }
}

export default App;
