import styled from "styled-components";
export const minBigLaptopBreakpoints = 1200;
export const minLaptopBreakpoints = 992;
export const minTabletBreakpoints = 768;
export const minMobileBreakpoints = 576;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const FlexibleRowContainer = styled(RowContainer)`
  @media only screen and (max-width: ${minTabletBreakpoints - 1}px) {
    flex-direction: column;
  }
`;

export const FlexibleVerticalContainer = styled(VerticalContainer)`
  @media only screen and (max-width: ${minTabletBreakpoints - 1}px) {
    flex-direction: row;
  }
`;
