import "../index.css";
import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Home from "../pages/Home";
import Register from "../pages/Register";
import Notice from "../pages/Notice";
import Books from "../pages/Books";

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/notice" component={Notice} />
          <Route exact path="/book" component={Books} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
