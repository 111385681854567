import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
//Styles
import {
  VerticalContainer,
  RowContainer,
  FlexibleRowContainer,
  minTabletBreakpoints,
  minMobileBreakpoints,
  minLaptopBreakpoints,
} from "../components/Layout";
import { BlueColor, GreenColor, WhiteColor } from "../utils/Color";
import { Title1, Title2, Title5 } from "../components/Typography";
//Components
import Header from "../components/Header";
import Footer from "../components/Footer";
import WindowResizer from "../components/WindowResizer";
//Assets
import engBooks1 from "../assets/eng-storybooks-1.png";
import engBooks2 from "../assets/eng-storybooks-2.png";
import bmBooks1 from "../assets/bm-storybooks-1.png";
import bmBooks2 from "../assets/bm-storybooks-2.png";
//Utils
import bookList from "../utils/BooksTranslation";
import bookImageList from "../utils/BooksImageTranslation";

class Success extends Component {
  renderTitle = (key) => {
    let title = bookList[key].title;
    if (this.props.authStore && this.props.authStore.userLanguage === "BM")
      title = bookList[key].titleTranslation;
    return title;
  };

  renderImage = (key) => {
    let image = bookImageList[key].image;
    if (this.props.authStore && this.props.authStore.userLanguage === "BM")
      image = bookImageList[key].imageTranslation;
    return image;
  };

  render() {
    return (
      <VerticalContainer>
        <WindowResizer />
        <Header />
        <ContentContainer>
          <RowContainer style={{ flexWrap: "wrap", marginTop: 10 }}>
            <VerticalContainer style={{ margin: 5 }}>
              <SmallBookPreview src={engBooks1} />
              <BookTitle>{this.renderTitle(0)}</BookTitle>
            </VerticalContainer>
            <VerticalContainer style={{ margin: 5 }}>
              <SmallBookPreview src={engBooks2} />
              <BookTitle>{this.renderTitle(0)}</BookTitle>
            </VerticalContainer>
            <VerticalContainer style={{ margin: 5 }}>
              <SmallBookPreview src={bmBooks1} />
              <BookTitle>Bahasa Malaysia</BookTitle>
            </VerticalContainer>
            <VerticalContainer style={{ margin: 5 }}>
              <SmallBookPreview src={bmBooks2} />
              <BookTitle>Bahasa Malaysia</BookTitle>
            </VerticalContainer>
          </RowContainer>

          <FlexibleRowContainer style={{ margin: "40px 0px", width: "100%" }}>
            <SelectedBookPreview src={this.renderImage(6)} />
            <SelectedBookContent>
              <BookCategoryContainer>
                <BookCategory>{this.renderTitle(1)}</BookCategory>
                <BookCategory>{this.renderTitle(2)}</BookCategory>
                <BookCategory>{this.renderTitle(3)}</BookCategory>
              </BookCategoryContainer>
              <Title1 style={{ color: BlueColor }}>
                {this.renderTitle(4)}
              </Title1>
              <BookAuthor>{this.renderTitle(5)}</BookAuthor>
              <BookAuthor>{this.renderTitle(6)}</BookAuthor>
              <br />
              <BookDescription>{this.renderTitle(7)}</BookDescription>
              <br />
              <BookDescription>{this.renderTitle(8)}</BookDescription>
            </SelectedBookContent>
          </FlexibleRowContainer>

          <BookContentImage src={this.renderImage(0)} />
          <BookContentImage src={this.renderImage(1)} />
          <BookContentImage
            src={this.renderImage(2)}
            style={{ marginBottom: 40 }}
          />

          <FlexibleRowContainer style={{ margin: "40px 0px", width: "100%" }}>
            <SelectedBookPreview src={this.renderImage(7)} />
            <SelectedBookContent>
              <BookCategoryContainer>
                <BookCategory>{this.renderTitle(1)}</BookCategory>
                <BookCategory>{this.renderTitle(2)}</BookCategory>
                <BookCategory>{this.renderTitle(3)}</BookCategory>
                <BookCategory>STEM</BookCategory>
              </BookCategoryContainer>
              <Title1 style={{ color: BlueColor }}>
                {this.renderTitle(9)}
              </Title1>
              <BookAuthor>{this.renderTitle(5)}</BookAuthor>
              <BookAuthor>{this.renderTitle(6)}</BookAuthor>
              <br />
              <BookDescription>{this.renderTitle(10)}</BookDescription>
              <br />
            </SelectedBookContent>
          </FlexibleRowContainer>

          <BookContentImage src={this.renderImage(3)} />
          <BookContentImage src={this.renderImage(4)} />
          <BookContentImage
            src={this.renderImage(5)}
            style={{ marginBottom: 40 }}
          />
        </ContentContainer>
        <Footer />
      </VerticalContainer>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    authStore: state.authStore,
    utilsStore: state.utilsStore,
  };
};

export default connect(mapStateToProps, null)(Success);

const ContentContainer = styled(VerticalContainer)`
  width: 80%;
  max-width: 960px;
`;

const SmallBookPreview = styled.img`
  width: calc(20vw - 10px);
  max-width: 230px;

  @media only screen and (max-width: ${minLaptopBreakpoints - 1}px) {
    width: calc(40vw - 10px);
    max-width: none;
  }

  @media only screen and (max-width: ${minTabletBreakpoints - 1}px) {
    width: calc(80vw - 10px);
    max-width: 280px;
  }
`;

const SelectedBookPreview = styled.img`
  width: 30%;
  margin-right: 40px;

  @media only screen and (max-width: ${minTabletBreakpoints - 1}px) {
    width: 60%;
    max-width: 300px;
    margin-bottom: 20px;
    margin-right: 0px;
  }
`;

const SelectedBookContent = styled(VerticalContainer)`
  width: calc(70% - 40px);
  align-items: flex-start;

  @media only screen and (max-width: ${minTabletBreakpoints - 1}px) {
    width: 100%;
  }
`;

const BookContentImage = styled.img`
  width: 100%;
  margin-bottom: 10px;
`;

const BookCategoryContainer = styled(RowContainer)`
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const BookCategory = styled.div`
  background-color: ${GreenColor};
  border-radius: 20px;
  color: ${WhiteColor};
  border: none;
  font-family: "doctorSoosBold";
  font-size: 18px;
  padding: 5px 15px;
  margin: 5px;
  margin-left: 0px;
  text-align: center;

  @media only screen and (max-width: ${minMobileBreakpoints - 1}px) {
    font-size: 16px;
  }
`;

const BookTitle = styled(Title2)`
  color: ${BlueColor} !important;
  font-size: 25px !important;
`;

const BookAuthor = styled(Title5)`
  font-family: "visbyRound";
  color: ${BlueColor} !important;
  font-weight: 900;
`;

const BookDescription = styled(Title5)`
  font-family: "visbyRound";
  color: #949494 !important;
  font-weight: 900;
`;
